/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import CreateAddress from 'components/OpenServiceOrder/CreateAddress';
import CustomButton from 'components/Shared/CustomButton';
import FormValidator from 'components/Shared/FormFields/FormValidator';
import UploadFiles from 'components/Shared/UploadFiles';
import LocationsContext from 'contexts/LocationsContext/LocationsContext';
import { OpenServiceOrderContextElement } from 'contexts/OpenServiceOrderContext/OpenServiceOrderContext';
import { OpenServiceOrderContextType } from 'contexts/OpenServiceOrderContext/OpenServiceOrderContextType';
import { Address } from 'models/Address';
import Equipment from 'models/Equipment';
import FileProperties from 'models/FileProperties';
import { ServiceOrderInput } from 'models/ServiceOrder';
import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { deleteFileOnS3 } from 'services';
import { store } from 'store';
import { DefaultServiceOrderAddress, SERVICE_ORDER_ORIGIN, SERVICE_ORDER_TYPE } from 'utils/constants';
import { parseEquipName } from 'utils/pipe';
import AddressField from './AddressField';
import OpenServiceOrderResponse from './OpenServiceOrderResponse';
import './styles.scss';

interface Params {
	equipment: Equipment;
	onClose: (hasUpdate: boolean) => void;
}

const OpenServiceOrder: React.FC<Params> = ({ equipment, onClose }) => {
	const { createServiceOrder } = useContext<OpenServiceOrderContextType>(OpenServiceOrderContextElement);
	const [files, setFiles] = useState<FileProperties[]>([]);
	const [serviceOrderCode, setServiceOrderCode] = useState<string>();
	const [showServiceOrderResponse, setShowServiceOrderResponse] = useState<boolean>(false);
	const [showCreateAddressForm, setShowCreateAddressForm] = useState<boolean>(false);
	const {
		register,
		handleSubmit,
		clearErrors,
		setValue,
		formState: { errors, isDirty },
	} = useForm<Address>();

	const user = store.getState().data.user;
	const client = store.getState().data?.client;

	const [text, setText] = useState<string>('');
	const [msg, setMsg] = useState<number>(500);

	const getEquipAddress = () => {
		const locationData = equipment.location ? equipment.location : client;
		const address = `${locationData.street}, ${locationData.neighborhood}.`;
		const region = `${locationData.city.toLocaleLowerCase()}, ${locationData.state_sigla} - ${locationData.zip_code}`;

		return `${address.toLocaleLowerCase()} ${region}`;
	};

	const onAddressSelected = (value: DefaultServiceOrderAddress) => {
		if (value === DefaultServiceOrderAddress.ADD_NEW_VALUE) {
			setShowCreateAddressForm(true);
		} else {
			setShowCreateAddressForm(false);
			clearErrors();
		}
	};

	const onConfirm = (data: Address) => {
		setServiceOrderCode('');
		setShowServiceOrderResponse(true);
		const equipLocation = equipment.location ? equipment.location : client;
		const address = showCreateAddressForm ? `${data.address}, ${data.addressNumber}` : equipLocation?.street;
		const neighborhood = showCreateAddressForm ? data.neighborhood : equipLocation?.neighborhood;
		const city = showCreateAddressForm ? data.city : equipLocation?.city;
		const state_sigla = showCreateAddressForm ? data.stateSigla : equipLocation?.state_sigla;
		const complement = showCreateAddressForm ? data.complement : equipLocation?.complement;
		const zip_code = showCreateAddressForm ? data.zipCode : equipLocation?.zip_code;
		const message = data.obsMessage || '';
		const addressDefault = data.addressDefault;

		const location = { complement, address, neighborhood, city, state_sigla, zip_code };

		const serviceOrder = {
			created_by: user.id,
			origin: SERVICE_ORDER_ORIGIN,
			client_code: client.cod_protheus,
			service_order: '',
			contato: user.name,
			phone: user.phone,
			email: user.email,
			equipment_id: equipment.id,
			type: SERVICE_ORDER_TYPE,
			obs: data.obsMessage,
			closure_status: null,
			...location,
			addressDefault,
		} as ServiceOrderInput;

		createServiceOrder(equipment, serviceOrder, message, files, onCreateServiceOrder);
	};

	const onCreateServiceOrder = (res: any, err?: any) => {
		if (!err && res) {
			setServiceOrderCode(res);
		} else {
			setShowServiceOrderResponse(false);
			toast.error('Erro ao solicitar atendimento');
		}
	};

	const onAddFile = (newFiles: FileProperties[]) => {
		const oldFiles = [...files];
		oldFiles.push(...newFiles);
		setFiles(oldFiles);
	};

	function handleDelete() {
		files.map(file => {
			deleteFileOnS3(file.id);
		});
	}

	const onRemoveFile = async (file: FileProperties) => {
		const currentIndex = files.findIndex((f: FileProperties) => f.id === file.id);
		try {
			await deleteFileOnS3(file.id);
		} catch (error) {
			console.error(error);
		}
		const newFileList = [...files];
		newFileList.splice(currentIndex, 1);
		setFiles(newFileList);
	};

	const closeServiceOrderResponse = () => {
		setShowServiceOrderResponse(false);
		onClose(true);
	};

	const countMsg = (e: any) => {
		if (text.length < 500) {
			setText(e.target.value.substring(0, 500));
			if (e.nativeEvent.inputType == 'insertText' || e.nativeEvent.inputType == 'insertFromPaste') {
				setMsg(500 - (text.length + 1));
			} else {
				setMsg(500 - (text.length - 1));
			}
		} else {
			if (e.nativeEvent.inputType == 'insertText' || e.nativeEvent.inputType == 'insertFromPaste') {
				setMsg(0);
			} else {
				setText(e.target.value.substring(0, 500));
				setMsg(500 - (text.length + 1));
			}
		}
	};

	const serviceOrderMessageContent = () => {
		return (
			<div className="form-item-content message">
				<label>Relate um problema</label>
				<textarea
					name={'obsMessage'}
					{...register('obsMessage', { required: false, maxLength: 255 })}
					placeholder="Descreva aqui o problema de seu equipamento"
					onChange={e => {
						countMsg(e);
					}}
					value={text}
				/>
				<span className="characters-remaining">{msg} caracteres restantes</span>
			</div>
		);
	};

	const uploadFilesContent = () => {
		return <UploadFiles {...{ fileList: files, onAddFile, onRemoveFile, limit: 3 }} />;
	};

	const formValidatorsContent = () => {
		return (
			<div className="form-content-validator">
				<FormValidator {...{ formName: 'zipCode', validatorName: 'CEP', errors, isDirty }} />
				<FormValidator {...{ formName: 'city', validatorName: 'Cidade', errors, isDirty }} />
				<FormValidator {...{ formName: 'stateSigla', validatorName: 'UF', errors, isDirty }} />
				<FormValidator {...{ formName: 'address', validatorName: 'Endereço', errors, isDirty }} />
				<FormValidator {...{ formName: 'addressNumber', validatorName: 'N°', errors, isDirty }} />
				<FormValidator {...{ formName: 'neighborhood', validatorName: 'Bairro', errors, isDirty }} />
				<FormValidator {...{ formName: 'complement', validatorName: 'Complemento', errors, isDirty }} />
			</div>
		);
	};

	return (
		<div className="open-service-order-content">
			<form onSubmit={handleSubmit(onConfirm)} className="main-form-content">
				<div className="service-order-address-content">
					<div className="service-order-equip-name">
						<span>{parseEquipName(equipment.name)}</span>
					</div>
					<AddressField {...{ equipAddress: getEquipAddress(), onAddressSelected }} />
					<LocationsContext>
						<CreateAddress {...{ register, errors, setValue, show: showCreateAddressForm }} />
					</LocationsContext>
					{serviceOrderMessageContent()}
					{uploadFilesContent()}
					{formValidatorsContent()}
				</div>
				<div className="open-service-actions">
					<CustomButton context="primary" type="submit" label="ENVIAR" />
					<CustomButton
						context="secondary"
						label="CANCELAR"
						onClick={async () => {
							await handleDelete();
							onClose(false);
						}}
					/>
				</div>
			</form>
			<OpenServiceOrderResponse {...{ serviceOrderCode, onClose: closeServiceOrderResponse, show: showServiceOrderResponse }} />
		</div>
	);
};

export default OpenServiceOrder;
